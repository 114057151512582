import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import React from "react";

const Testimonial = ({display, onShowMore, showMore, display2}:{display: boolean, onShowMore:() => void, display2: boolean, showMore:() => void}) => {
  return <div className="testimonial-bg overflow-hidden content-space-t-0">
      <h2 className="container text-white space-bottom">Ils nous ont fait confiance</h2>
      <div className="container content-space-1">
          <div className="swiper-wrapper">
              <Swiper
                  modules={[Autoplay, Pagination, Navigation]}
                  slidesPerView={1}
                  spaceBetween={10}
                  loop={true}
                  onSlideChange={() => {}}
                  onSwiper={(swiper) => {}}
                  autoplay={{delay: 5000, disableOnInteraction: false,}}
                  breakpoints={{
                      640: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                      },
                      768: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                      },
                      1024: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                      },
                  }}
              >
                  <SwiperSlide>
                      <div>
                          <div className="testimonial">
                              <blockquote>
                                  J'ai toujours aimé acheté en ligne surtout quand on vit
                                  dans un pays en voie de développement où l'on a
                                  forcement pas tout à disposition.
                                  {display ? '' : <span>...</span>}<span id={display ? "" : "more"}>
                                                    J'en ai essayé plusieurs et AFRISENDS reste les meilleurs et les plus
                                                    fiables! Un service clients au top avec une personne(Zeïnabou Sidibé)
                                                    tres amicales qui nous conseille dans nos achats!
                                                    Grace à eu le shopping est vraiment plus simple!</span>
                                  <button className="btn" onClick={onShowMore}
                                          id="myBtn">{display ? "Moins" : "Lire la suite"}</button>
                              </blockquote>
                              <br/>
                              <p>
                                  Sabrine Wayzani &mdash; Mali
                              </p>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div>
                          <div className="testimonial">
                              <blockquote>
                                  Je recommande Afrisend pour le professionnalisme et la
                                  rapidité.Mes colis sont bien emballés et arrivent en
                                  partfait état.Avec eux j'ai développé le réflexe du
                                  shopping en ligne
                              </blockquote>
                              <br/>
                              <p>
                                  Fatoumata Mariko &mdash; Mali
                              </p>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div>
                          <div className="testimonial">
                              <blockquote>
                                  Afrisend est apparue dans mon fonctionnement telle une oasis
                                  en plein désert facilitant notamment l'achat et l'acheminement de
                                  biens
                                  Je recommande professionnalisme vivement cette entreprise qui fait
                                  d'un
                                  exemplaire
                              </blockquote>
                              <br/>
                              <p>
                                  Thuriaf.M &mdash; un Gabonais au Mali
                              </p>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div>
                          <div className="testimonial">
                              <blockquote>
                                  Je suis toujours à la recherche de solution d'envoie vers
                                  le Mali (principalement) mais pas que! Quand j'ai
                                  découvert Afrisends j'ai été conquise (le graal)!<br/>
                                  De mon premier envoi vers la
                                  {display2 ? '' : <span>...</span>}<span id={display2 ? "" : "more"}>
                                                    Jamaïque à tous les autres qui ont suivi (vers le Mali et la Côte d'Ivoire) je n'ai jamais été
                                                    déçue De la prise en charge à la livraison, une
                                                    réactivité à la hauteur de l'accueil et du niveau de prestation!</span>
                                  <button className="btn"
                                          onClick={showMore}>{display2 ? "Moins" : "Lire la suite"}</button>
                              </blockquote>
                              <br/>
                              <p>
                                  Mariam Sissoko &mdash; Mali
                              </p>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div>
                          <div className="testimonial">
                              <blockquote>
                                  J'ai découvert Afrisend via Instagram
                                  Au début je n'avais pas confiance, mais depuis
                                  presque 9 mois que nous fonctionnons ensemble
                                  Je n'ai jamais eu de faux coup ni de faux rendez
                                  vous Je la recommande vivement.
                                  Je suis satisfaite par ses services.
                              </blockquote>
                              <br/>
                              <p>
                                  Mariane Toukam &mdash; Mali
                              </p>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div>
                          <div className="testimonial">
                              <blockquote>
                                  J'ai toujours reçu mes commandes intactes,bien
                                  emballés. Afrisends c'est la fiabilité à 100%
                                  avec un service client au top. Je suis
                                  entièrement satisfaite de leurs prestations
                              </blockquote>
                              <br/>
                              <p>
                                  Aissata Coulibaly &mdash; Mali
                              </p>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div>
                          <div className="testimonial">
                              <blockquote>
                                  Commande livrer à temps et en parfait état.
                                  Satisfait à 100%, je recommande !
                              </blockquote>
                              <br/>
                              <p>
                                  Idrissa Altiné &mdash; Niger
                              </p>
                          </div>
                      </div>
                  </SwiperSlide>
              </Swiper>
          </div>
      </div>
  </div>
}

export default Testimonial;
import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import "/static/assets/css/HomeContent.css";
import {useMediaQuery} from "../../util/media-query";
import Offers from "./offers";
import Pricing from "./pricing";
import HowItsWork from "./how-its-work";
import About from "./about";
import Testimonial from "./testmonial";
import Store from "./store";
import B2BOffers from "./b2b-offers";

const HomeContent = () => {
    const [display2, setDisplay2] = useState(false);
    const [display, setDisplay] = useState(false);
    const isMobile = useMediaQuery('(max-width: 850px)')

    function showMore() {
        setDisplay(!display);
    }

    function showMore2() {
        setDisplay2(!display2);
    }
    return <>
        <div>
            {/*SLIDER*/}
            <div className="content-space-t-2 content-space-t-lg-2">
                <div className="js-swiper-blog-modern-hero swiper swiper-equal-height">
                    <div className="swiper-wrapper">
                        <Swiper
                            slidesPerView={1}
                            loop={true}
                            onSlideChange={() => {}}
                            onSwiper={(swiper) => {}}
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{delay: 3000, disableOnInteraction: false}}
                            navigation={false}
                            modules={[Autoplay, Pagination, Navigation]}
                        >
                            <SwiperSlide>
                                <div
                                    className="swiper-slide bg-img-start content-space-2 content-space-t-sm-3 content-space-b-sm-4"
                                    style={{ background: `linear-gradient(rgba(0, 0, 0, 0.2) 10%, rgba(239,136,69,0.2) 99%), linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(239,136,69,0.2) 90%), url(/assets/img/Slide2.jpg) no-repeat ${isMobile?'center/200%':'center/100%'}` }}
                                    // style={{backgroundImage: "url(/assets/img/others/delivery.jpg)"}}
                                >
                                    <div className="container content-space-b-sm-2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-5" >
                                                    <h2 className="h1 text-white mb-4">
                                                        Votre logistique  <br/> clefs en main
                                                    </h2>
                                                </div>
                                                <br/>
                                                <a className="btn btn-light btn-pointer" href="/app/simulator" style={{marginBottom:isMobile?'15px':'0px'}}>
                                                    Testez notre simulateur
                                                </a>
                                                <a className="btn btn-primary btn-pointer" href="/app/place-order" style={{marginLeft:isMobile?'0px':'20px'}}>
                                                    Créez une commande
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div
                                    className="swiper-slide bg-img-start content-space-2 content-space-t-sm-3 content-space-b-sm-4"
                                    style={{ background: `linear-gradient(rgba(0, 0, 0, 0.2) 10%, rgba(239,136,69,0.2) 99%), linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(239,136,69,0.2) 90%), url(/assets/img/1920x1080/afrisends-home-2.jpg) no-repeat ${isMobile?'center/200%':'center/100%'}` }}
                                    // style={{backgroundImage: "url(/assets/img/1920x1080/afrisends-home-2.jpg)"}}
                                >
                                    <div className="container content-space-b-sm-2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-5">
                                                    <h2 className="h1 text-white">
                                                        Vos colis en un clic, <br/> aussi simple et efficace que ça !
                                                    </h2>
                                                </div>
                                                <a className="btn btn-light btn-pointer" href="/app/simulator" style={{marginBottom:isMobile?'15px':'0px'}}>
                                                    Testez notre simulateur
                                                </a>
                                                <a className="btn btn-primary btn-pointer" href="/app/place-order" style={{marginLeft:isMobile?'0px':'20px'}}>
                                                    Créez une commande
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div
                                    className="swiper-slide bg-img-start content-space-2 content-space-t-sm-3 content-space-b-sm-4"
                                    style={{ background: `linear-gradient(rgba(0, 0, 0, 0.2) 10%, rgba(239,136,69,0.2) 99%), linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(239,136,69,0.2) 90%), url(/assets/img/Entreposage.jpg) no-repeat ${isMobile?'center/200%':'center/100%'}` }}
                                    // style={{backgroundImage: "url(/assets/img/others/delivery.jpg)"}}
                                >
                                    <div className="container content-space-b-sm-2 pt-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-5">
                                                    <h2 className="h1 text-white">
                                                        Nous nous occupons  <br/> de votre stockage
                                                    </h2>
                                                </div>
                                                <a className="btn btn-light btn-pointer" href="/app/simulator" style={{marginBottom:isMobile?'15px':'0px'}}>
                                                    Testez notre simulateur
                                                </a>
                                                <a className="btn btn-primary btn-pointer" href="/app/place-order" style={{marginLeft:isMobile?'0px':'20px'}}>
                                                    Créez une commande
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div
                                    className="swiper-slide bg-img-start content-space-2 content-space-t-sm-3 content-space-b-sm-4"
                                    style={{ background: `linear-gradient(rgba(0, 0, 0, 0.2) 10%, rgba(239,136,69,0.2) 99%), linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(239,136,69,0.2) 90%), url(/assets/img/1920x1080/af-home-3.jpg) no-repeat  ${isMobile?'center/200%':'center/100%'}` }}
                                    // style={{backgroundImage: "url(/assets/img/1920x1080/af-home-3.jpg)"}}
                                >
                                    <div className="container content-space-b-sm-2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-5">
                                                    <h2 className="h1 text-white">
                                                        Faites vos achats en ligne en toute sérénité avec Afrisends
                                                    </h2>
                                                </div>
                                                <a className="btn btn-light btn-pointer" href="/app/simulator" style={{marginBottom:isMobile?'15px':'0px'}}>
                                                        Testez notre simulateur
                                                </a>
                                                <a className="btn btn-primary btn-pointer" href="/app/place-order" style={{marginLeft:isMobile?'0px':'20px'}}>
                                                    Créez une commande
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div
                        className="js-swiper-blog-modern-hero-pagination swiper-pagination swiper-pagination-light position-absolute bottom-0 start-0 end-0 mb-3 d-sm-none"/>
                </div>
                <div className="position-sm-absolute bottom-0 start-0 end-0 zi-2 d-none d-sm-block mb-7">
                    <div className="container content-space-t-1">
                        <div
                            className="js-swiper-blog-modern-hero-thumbs swiper swiper-step-pagination swiper-step-pagination-light">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <span className="swiper-step-pagination-title">Afrisends</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <B2BOffers/>
            {/*NOS OFFRES*/}
            <Offers/>
            {/*NOS TARIF*/}
            <Pricing/>
            {/*HOW IT WORK*/}
            <HowItsWork/>
            {/*LES CHIFFRES*/}
            {/*<About/>*/}
            {/*AVIS CLIENT*/}
            <Testimonial display={display} onShowMore={showMore} display2={display2} showMore={showMore2}/>
            {/*MOST VISITED*/}
            <Store/>
        </div>
        {/*MODAL FOR TUTORIELS*/}
    </>
}

export default HomeContent;

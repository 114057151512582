import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import React from "react";

const Store = () => {
  return <div className="overflow-hidden container">
      <h2 className="border-bottom space-bottom">Les boutiques les plus visitées</h2>
      <div className="row content-space-1">
          <div className="swiper-wrapper">
              <Swiper
                  modules={[Autoplay, Pagination, Navigation]}
                  slidesPerView={1}
                  spaceBetween={10}
                  loop={true}
                  onSlideChange={() => {
                  }}
                  onSwiper={(swiper) => {}}
                  autoplay={{delay: 2000, disableOnInteraction: false,}}
                  breakpoints={{
                      640: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                      },
                      768: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                      },
                      1024: {
                          slidesPerView: 5,
                          spaceBetween: 20,
                      },
                  }}
              >
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/zara.svg"
                                   alt="Zara"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/amazon.svg"
                                   alt="Amazon"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/shein.svg"
                                   alt="Shein"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/sephora.svg"
                                   alt="Sephora"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/nike.svg"
                                   alt="Nike"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/ikea.png"
                                   alt="Ikea"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/veepee.svg"
                                   alt="Veepee"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/AliExpress.svg"
                                   alt="Aliexpress"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar boutique-img"
                                   src="/assets/svg/brands/nespresso.svg"
                                   alt="Nespresso"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/zalando.svg"
                                   alt="zalando"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/mango.svg"
                                   alt="Mango"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/chanel.svg"
                                   alt="Chanel"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/chaussea.jpg"
                                   alt="Chaussea"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/dior.svg"
                                   alt="Dior"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/MK.png"
                                   alt="Michael kors"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="https://www.PrettyLittleThing.com" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/PrettyLittleThing-logo.jpg"
                                   alt="PrettyLittleThing"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="https://www.LookFantastic.com" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/lookfantastic.png"
                                   alt="LookFantastic"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/cdiscount.svg"
                                   alt="cdiscount"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/fnac.svg"
                                   alt="Fnac"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/carrefour.svg"
                                   alt="Carrefour"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/leroy_merlin.svg"
                                   alt="Leroymerlin"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/darty.svg"
                                   alt="Darty"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/apple.svg"
                                   alt="Apple"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/decathlon.svg"
                                   alt="Decathlon"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/boulanger.svg"
                                   alt="Boulanger"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/la_redoute.svg"
                                   alt="La redoute"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/ebay.svg"
                                   alt="ebay"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/but.svg"
                                   alt="But"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/rue_du_commerce.svg" alt="rueducommerce"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent"
                         className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/christian-louboutin.svg"
                                   alt="christian-louboutin"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank"
                         href="https://www.row.jimmychoo.com/en/home?gclid=Cj0KCQjwwLKFBhDPARIsAPzPi-IfWeMh-tsbauIlYomYGLE08II58yzWVOHN2IjSfUPlfUZ8LQv8XbIaAuZNEALw_wcB&gclsrc=aw.ds/"
                         className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/jimiChoo.svg"
                                   alt="jimmychoo"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/lancel.png"
                                   alt="Lancel"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/etam.svg"
                                   alt="Etam"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="https://www.mangooutlet.com/fr/femme" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/mangooutlet.svg"
                                   alt="Mango Outlet"/>
                          </div>
                      </a>
                  </SwiperSlide>
                  <SwiperSlide>
                      <a target="_blank" href="src/components/home/HomeContent" className="text-center">
                          <div className="mb-3">
                              <img className="avatar-lg-custom boutique-img"
                                   src="/assets/svg/brands/polene.png"
                                   alt="Polène Paris"/>
                          </div>
                      </a>
                  </SwiperSlide>
              </Swiper>
          </div>
      </div>
  </div>;
}

export default Store;
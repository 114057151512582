import React from "react"
import HomeContent from "../components/home/HomeContent";
import HSHeader from "../plugins/header";
import HSGoTo from "../plugins/go-to-top";
import {useEffect} from "react";
import Layout from "../components/layout/Layout";
import 'swiper/css';
// markup
const IndexPage = () => {

    useEffect(() => {
        const header = new HSHeader('#header');
        header.init();
        new HSGoTo('.js-go-to');

        // INITIALIZATION OF MEGA MENU
        // =======================================================
        /*new HSMegaMenu('.js-mega-menu', {
            desktop: {
                position: 'left'
            }
        })*/
        // INITIALIZATION OF SCROLLSPY
        // =======================================================
        /*new bootstrap.ScrollSpy(document.body, {
            target: '#navbarVerticalNavMenuSettingsEg',
            offset: 10
        })*/

        /*new HSScrollspy('#navbarVerticalNavMenuEg', {
            breakpoint: 'lg'
        })*/
        // INITIALIZATION OF GO TO
        // =======================================================

        })
  return (
    <>
        <Layout  title={'Vos colis en un clic, aussi simple et efficace que ça !'}>
            <HomeContent/>
        </Layout>
    </>
  )
}

export default IndexPage

import React from "react";

const Pricing = () => {

    return <div id="generic_price_table">
        <div className="container">
            <div className="price-heading clearfix">
                <h1>Nos tarifs d'expédition pour les particuliers
                    <p style={{fontSize: 15, marginTop: 15, marginBottom: 30}}>
                        Le paiement s’effectue par Orange money, Wave, Zamani cash, virement bancaire, Paypal, Western Union.
                    </p>
                </h1>
            </div>
        </div>
        <div className="bg-dark">
            <div className="container" style={{padding: 25}}>
                <div className="row" style={{marginTop: 35, marginBottom: -40}}>
                    <div className="col-md-4">
                        <div className="generic_content clearfix">
                            <div className="generic_head_price clearfix">
                                <div className="generic_head_content clearfix">
                                    <div className="head_bg"/>
                                    <div className="head">
                                        <span>AÉRIEN FRANCE</span>
                                    </div>
                                </div>
                                <div className="generic_price_tag clearfix">
                                    <div style={{fontSize: 12}}>À partir de</div>
                                    <span className="price">
                                                <span className="currency">4 500</span>
                                                <span className="cent"> F CFA</span>
                                                <span className="month">/KG</span>
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="generic_content active clearfix">
                            <div className="generic_head_price clearfix">
                                <div className="generic_head_content clearfix">
                                    <div className="head_bg"/>
                                    <div className="head">
                                        <span>AÉRIEN USA</span>
                                    </div>
                                </div>
                                <div className="generic_price_tag clearfix">
                                    <div style={{fontSize: 12}}>À partir de</div>
                                    <span className="price">
                                                <span className="currency">14 500</span>
                                                <span className="cent">F CFA</span>
                                                <span className="month">/KG</span>
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-md-4">
                        <div className="generic_content clearfix">
                            <div className="generic_head_price clearfix">
                                <div className="generic_head_content clearfix">
                                    <div className="head_bg"/>
                                    <div className="head">
                                        <span>AÉRIEN CHINE</span>
                                    </div>
                                </div>
                                <div className="generic_price_tag clearfix">
                                    <div style={{fontSize: 12}}>À partir de</div>
                                    <span className="price">
                                                <span className="currency">9 500</span>
                                                <span className="cent"> F CFA</span>
                                                <span className="month">/KG</span>
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="col-md-4">
                        <div className="generic_content clearfix">
                            <div className="generic_head_price clearfix">
                                <div className="generic_head_content clearfix">
                                    <div className="head_bg"/>
                                    <div className="head">
                                        <span>AÉRIEN CHINE</span>
                                    </div>
                                </div>
                                <div className="generic_price_tag clearfix">
                                    <div style={{fontSize: 12}}>À partir de</div>
                                    <span className="price">
                                                <span className="currency">10 400</span>
                                                <span className="cent"> F CFA</span>
                                                <span className="month">/KG</span>
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col-md-12">
                            <div className="generic_content clearfix">
                                <div className="generic_head_price clearfix">
                                    <div className="generic_head_content clearfix">
                                        <div className="head_bg"/>
                                        <div className="head">
                                            <span>MARITIME</span>
                                        </div>
                                    </div>
                                    <div className="generic_price_tag clearfix">
                                        <div style={{fontSize: 12, paddingBottom: 18}}/>
                                        <span className="price">
                                                <span className="currency"> <a
                                                    href="mailto:commande@afrisends.com">Devis </a></span>
                                                <span className="cent"> Prix</span>
                                                <span className="month">  au m3</span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-md-4">
                                <div className="generic_content clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"/>
                                            <div className="head">
                                                <span>Economy</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="currency">8 500</span>
                                                <span className="cent"> F CFA</span>
                                                <span className="month">/kg</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            <li>Entre 0 - 500g</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="generic_content active clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"/>
                                            <div className="head">
                                                <span>Standard</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="currency">10 400</span>
                                                <span className="cent">F CFA</span>
                                                <span className="month">/kg</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            <li>
                                                Entre 500-10kg
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="generic_content clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"/>
                                            <div className="head">
                                                <span>Premium</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="currency">9 000</span>
                                                <span className="cent"> F CFA</span>
                                                <span className="month">/kg</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            <li>Plus de 10kg</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>*/}
                </div>
            </div>
        </div>
        {/*<div className="custom-bg" style={{paddingTop: 35,paddingBottom:30}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="generic_content clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"/>
                                            <div className="head">
                                                <span>AÉRIEN TURQUIE</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="currency">8 500</span>
                                                <span className="cent"> F CFA</span>
                                                <span className="month">/KG</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="col-md-12">
                                    <div className="generic_content clearfix">
                                        <div className="generic_head_price clearfix">
                                            <div className="generic_head_content clearfix">
                                                <div className="head_bg"/>
                                                <div className="head">
                                                    <span>Expédition USA- MALI</span>
                                                </div>
                                            </div>
                                            <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="currency">14 500</span>
                                                <span className="cent"> F CFA</span>
                                                <span className="month">/kg</span>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="col-md-12">
                                    <div className="generic_content clearfix">
                                        <div className="generic_head_price clearfix">
                                            <div className="generic_head_content clearfix">
                                                <div className="head_bg"/>
                                                <div className="head">
                                                    <span>MARITIME</span>
                                                </div>
                                            </div>
                                            <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="currency"> <a href="mailto:bonjour@afrisends.com">Devis </a></span>
                                                <span className="cent"> Prix</span>
                                                <span className="month">  au m3</span>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
    </div>;
}
export default Pricing;
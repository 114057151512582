import React from "react";

const B2BOffers = () => {

    return <div className="container content-space-t-2">
        <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
            <h1 className="display-5">Entreprises</h1>
            <p className="lead">
                Besoin de sourcer, acheter, expédier et entreposer vos marchandises ? Nous sommes prêts à vous accompagner du simple colis à la flotte d’engins miniers.
            </p>
        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-2 gx-7">
            <div className="col mb-3 mb-md-5">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-pinned card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    height={360}
                                    src="/assets/img/Sourcing_Image.jpeg"
                                    alt="Image Description"
                                />
                            </div>
                            <div className="card-body">
                                <h4>Sourcing</h4>
                                <div className="card-text">
                                    Base de données de +200 fournisseurs à l’international pour réduire les coûts de votre entreprise
                                </div>
                            </div>
                        </span>
            </div>
            <div className="col mb-3 mb-md-5">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    height={360}
                                    src="/assets/img/Achat%20Image.jpg"
                                    alt="Image Description"
                                />
                            </div>
                            <div className="card-body">
                                <h4>Achat</h4>
                                <div className="card-text">
                                    Achat de vos marchandises et expéditions par cargo aérien ou maritime.
                                </div>
                            </div>
                        </span>
            </div>
            <div className="col mb-3 mb-md-5">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    height={360}
                                    src="/assets/img/Logistique.jpg"
                                    alt="Image Description"
                                />

                            </div>
                            <div className="card-body">
                                <h4>Logistique </h4>
                                <div className="card-text">
                                    Stockage, préparation et expédition de vos marchandises.
                                </div>
                            </div>
                        </span>
            </div>
            <div className="col mb-3 mb-md-5">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-pinned card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    height={360}
                                    src="/assets/img/Entreposage.jpg"
                                    alt="Image Description"
                                />
                            </div>
                            <div className="card-body">
                                <h4>Entreposage</h4>
                                <div className="card-text">
                                   Des entrepôts à disposition pour tous les besoins de stockage.
                                </div>
                            </div>
                        </span>
            </div>
        </div>
        <div className="text-center">
            <div className="align-content-center btn-test-simulator">
                <a className="btn btn-primary text-center" href="mailto:b2b@afrisends.com">Demander un devis sur b2b@afrisends.com &nbsp;<i
                    className="bi-box-arrow-up-right"/></a>
            </div>
        </div>
    </div>
}

export default B2BOffers;
import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";

const Offers = () => {

    return <div className="container content-space-t-2">
        <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
            <h1 className="display-5">Particuliers</h1>
            <p className="lead">
                Nos offres sont pensées et créées pour vous, n'hésitez pas à choisir celle qui vous
                convient la mieux.
            </p>
        </div>
        <div className="swiper-wrapper">
            <Swiper
                modules={[Autoplay, Pagination, Navigation]}
                slidesPerView={1}
                spaceBetween={10}
                navigation={true}
                loop={true}
                // onSwiper={(swiper) => {
                //     swiper.slideNext();
                // }}
                autoplay={{delay: 5000, disableOnInteraction: true, reverseDirection: true}}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                }}
            >
                <SwiperSlide>
                    <div className="col mb-7 mb-md-10">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-pinned card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/img/others/luxe7.jpg"
                                    alt="Image Description"
                                />
                                <span className="badge bg-dark text-white card-pinned-top-end">LUXE</span>
                            </div>
                            <div className="card-body">
                                <h4>Offre luxe - Shop 4 you</h4>
                                <div className="card-text">
                                    vous avez besoin des services d’un personal shopper ?<br/>
                                    Réservez un créneau d’une demi journée ou d’une journée,<br/>
                                    Afrisends se rendra dans le magasin de votre choix et votre shopping se fera par appel video.
                                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5"
                                        style={{marginTop: 10}}>
                                        <li className="list-checked-item">Forfait demi-Journée: 50 Euro</li>
                                        <li className="list-checked-item">Forfait journée Complète: 100 Euro</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-footer">
                                <a className="card-link text-center"
                                   href="https://api.whatsapp.com/send?phone=+33767622662&text=je suis intéressé(e) par votre offre luxe, pouvez vous m'aider ?"
                                   target="_blank">Souscrire
                                </a>
                            </div>
                        </span>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col mb-7 mb-md-10">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/img/others/luxe4.jpg"
                                    alt="Image Description"
                                />
                                 <span className="badge bg-dark text-white card-pinned-top-end">ZEN</span>
                            </div>
                            <div className="card-body">
                                <h4>Offre Zen - Take it easy</h4>
                                <div className="card-text">
                                    Vous n’avez pas de carte bancaire ou ne souhaitez pas l’utiliser ?<br/> Afrisends est là pour vous !<br/>
                                    Effectuez vos achats en ligne sur le site internet de votre choix en nous envoyant par Whatsapp les liens de vos articles. <br/>
                                    Nous effectuons le paiement pour votre compte et vous réexpédions vos articles dès réception à notre entrepôt.
                                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5"
                                        style={{marginTop: 10}}>
                                        <li className="list-checked-item">Cette offre comprend 10% de frais de service.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-footer">
                                <a className="card-link text-center"
                                   href="https://api.whatsapp.com/send?phone=+33767622662&text=je suis intéressé(e) par votre offre zen, pouvez vous m'aider ?"
                                   target="_blank">Souscrire
                                </a>
                            </div>
                        </span>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col mb-7 mb-md-10">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/img/others/luxe5.jpg"
                                    alt="Image Description"
                                />
                                <span className="badge bg-dark text-white card-pinned-top-end">FLEX</span>
                            </div>
                            <div className="card-body">
                                <h4>Offre Flex - Do it your way</h4>
                                <div className="card-text">
                                    Effectuez vos achats en ligne sur le site internet de votre choix, payez par votre carte bancaire et faites-vous livrer à l’adresse Afrisends. <br/>
                                    Nous vous réexpédions vos articles dès réception à notre entrepôt.
                                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5"
                                        style={{marginTop: 10}}>
                                        <li className="list-checked-item">Des frais de gestion de 2,5€ sont facturés par colis.</li>
                                        <li className="list-checked-item">A partir du cinquième colis, les frais sont plafonnés à 12,5€ peu importe le nombre de colis.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-footer">
                                <a className="card-link text-center"
                                   href="/app/place-order">Créez une commande
                                </a>
                            </div>
                        </span>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col mb-7 mb-md-10">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-pinned card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/img/others/delivery.jpg"
                                    alt="Image Description"
                                />
                                <span
                                    className="badge bg-dark text-white card-pinned-top-end">CHILL</span>
                            </div>
                            <div className="card-body">
                                <h4>Offre Chill - Ship it for me</h4>
                                <div className="card-text">
                                    Vous disposez déjà de votre colis et souhaitez l’expédier en Afrique ?<br/>
                                    Demandez une option pick up de votre colis à l’endroit de votre choix ou déposez le simplement à notre point de relais.
                                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5"
                                        style={{marginTop: 10}}>
                                        <li className="list-checked-item">Option Pick up payante en fonction de la distance</li>
                                        <li className="list-checked-item">Des frais de gestion de 2,5€ sont facturés par colis.</li>
                                        <li className="list-checked-item">A partir du cinquième colis, les frais sont plafonnés à 12,5€ peu importe le nombre de colis.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-footer">
                                <a className="card-link text-center"
                                   href="https://api.whatsapp.com/send?phone=+33767622662&text=je suis intéressé(e) par votre offre chill, pouvez vous m'aider ?"
                                   target="_blank">Souscrire
                                </a>
                            </div>
                        </span>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col mb-7 mb-md-10">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/img/others/cargo.jpg"
                                    alt="Image Description"
                                />
                                <span className="badge bg-dark text-white card-pinned-top-end">CARGO</span>
                            </div>
                            <div className="card-body">
                                <h4>Offre fret - Cargo</h4>
                                <div className="card-text">
                                    Vous souhaitez exporter des articles volumineux ou des véhicules vers l'Afrique. <br/>
                                    Nous faisons le pick up, l'emballage sécuritaire et l'expédition de votre marchandise en Afrique.<br/>
                                    Profitez de tarifs avantageux lors d'un groupage maritime.
                                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5"
                                        style={{marginTop: 10}}>
                                        <li className="list-checked-item">Option Pick up payante en fonction de la distance <br/>
                                            (Demandez un devis dès maintenant)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-footer">
                                <a className="card-link text-center"
                                   href="https://api.whatsapp.com/send?phone=+33767622662&text=je suis intéressé(e) par votre offre cargo, pouvez vous m'aider ?"
                                   target="_blank">Souscrire
                                </a>
                            </div>
                        </span>
                    </div>
                </SwiperSlide>
                {/*<SwiperSlide>
                    <div className="col mb-7 mb-md-10">
                        <span className="card card-ghost card-transition-zoom h-100">
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/img/others/pro.jpg"
                                    alt="Image Description"
                                />
                                <span className="badge bg-dark text-white card-pinned-top-end">PRO</span>
                            </div>
                            <div className="card-body">
                                <h4>Offre Pro - Business Services</h4>
                                <div className="card-text">
                                   Vous dirigez une PME ou une Grande Entreprise et vous avez des besoins de sourcing , d’achat , de logistique ou d’entreposage ?<br/>
                                    Nous mettons à votre disposition nos services sur mesure pour répondre à vos besoins.<br/>
                                    Contactez-nous par mail(<a href="mailto:b2b@afrisends.com">b2b@afrisends.com</a>) ou WhatsApp <a
                                    href="tel:+22379617979">+223 79 61 79 79</a> pour plus d’informations.
                                </div>
                            </div>
                            <div className="card-footer">
                                <a className="card-link text-center"
                                   href="https://api.whatsapp.com/send?phone=+33767622662&text=je suis intéressé(e) par votre offre  Pro - Business Services, pouvez vous m'aider ?"
                                   target="_blank">Souscrire
                                </a>
                            </div>
                        </span>
                    </div>
                </SwiperSlide>*/}

            </Swiper>
        </div>
        {/*<div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-7"></div>*/}
    </div>
}

export default Offers;
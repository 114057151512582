import React from "react";

const HowItsWork = () => {
  return <div className="container content-space-t-2 mb-4">
      <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-lg-6 mb-5 mb-lg-0">
              <img className="img-fluid rounded-3" src="/assets/img/others/sofa.jpg" alt="Image Description"/>
          </div>
          <div className="col-lg-5">
              <div className="mb-5">
                  <h2>Comment ça marche ?</h2>
              </div>
              <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                  <li className="list-checked-item">Choisissez l'offre qui vous convient la mieux et écrivez
                      nous sur Whatsapp
                  </li>
                  <li className="list-checked-item">Nous passons vos commandes après validation de votre
                      facture
                  </li>
                  <li className="list-checked-item">Vous recevez vos commandes à partir de 5 jours ouvrés dès
                      réceptions de vos colis chez Afrisends
                  </li>
              </ul>
          </div>
      </div>
  </div>;

}

export default HowItsWork;